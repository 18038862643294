const viewport = $(window);
const header = $('.header');
const tracker = $('.tracker');

let trackerSidebarWidth;

function trackerResize() {
  trackerSidebarWidth = parseInt(tracker.width());
}

function trackerScroll() {
  const scrollTop = viewport.scrollTop();
  const headerHeight = header.outerHeight();
  const trackerHeight = tracker.outerHeight();

  const trackerIni = tracker.parent().offset().top;
  const trackerEnd = tracker.parent().outerHeight() - trackerHeight;

  const trackerTopIni = trackerIni - headerHeight;
  const trackerTopEnd = trackerTopIni + trackerEnd;

  if (scrollTop < trackerTopIni) {
    tracker.css({ position: 'relative', top: 0 });
  } else if (scrollTop > trackerTopIni && scrollTop < trackerTopEnd) {
    tracker.parent().css({ minWidth: trackerSidebarWidth });
    tracker.css({ position: 'fixed', top: headerHeight });
  } else if (scrollTop > trackerTopEnd) {
    tracker.css({ position: 'relative', top: trackerEnd });
  }
}

if (tracker.length) {
  viewport.resize(trackerResize);
  viewport.scroll(trackerScroll);

  trackerResize();
}