const homeHeroScroll = $('.hero__scroll');

function init() {
  homeHeroScroll.on('click', anchor);

  setTimeout(function () { homeHeroScroll.removeClass('hero__scroll--hidden'); }, 100);
  setTimeout(function () { homeHeroScroll.addClass('hero__scroll--loop'); }, 900);
}

function anchor(e) {
  const anchorTop = $('#homeAnchor').offset().top;
  const headerHeight = $('.header').height();

  e.preventDefault();
  
  $('html, body').animate({ scrollTop: anchorTop - headerHeight }, 600);
}

if (window.page === 'home') {
  init();
}