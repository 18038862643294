import { isMobileSize } from './helpers';

const medias = $('.medias');
const mediasLoader = $('.medias__loader');
const mediasMore = $('.medias__more');
const mediasFilters = $('.filters');
const mediasFilter = $('.filter');
const mediasSelect = $('.select');
const grid = $('.grid');

const api = '/wp-json/api/v1/youtube/playlist';

let category = 'media';
let isotope = null;
let isotopeFilter = `.${category}`;

function init() {
  if (mediasFilters.length) {
    mediasFilter.on('click', function () {
      const clickedFilter = $(this);
      const selectedFilter = clickedFilter.attr('data-filter');

      category = selectedFilter.replace('.', '');
      isotopeFilter = selectedFilter;

      mediasFilter.removeClass('is-checked');
      clickedFilter.addClass('is-checked');

      if (mediasSelect) {
        mediasSelect.val(selectedFilter);
      }

      isotope.isotope('destroy');

      grid.empty();

      mediasLoader.addClass('medias__loader--loading');
      mediasMore.hide();

      if (category === "press" || category === "publications") {
        build(window[category]);
      } else {
        $.get(`${api}/${category}`, build);
      }
    });
  }

  if (mediasSelect.length) {
    mediasSelect.on('change', function () {
      const selectedFilter = $(this).val();

      category = selectedFilter.replace('.', '');
      isotopeFilter = selectedFilter;

      if (mediasFilters) {
        mediasFilter.removeClass('is-checked');
        mediasFilters.find('[data-filter="'+ selectedFilter +'"]').addClass('is-checked');
      }

      isotope.isotope('destroy');

      grid.empty();

      mediasLoader.addClass('medias__loader--loading');
      mediasMore.hide();

      if (category === "press" || category === "publications") {
        build(window[category]);
      } else {
        $.get(`${api}/${category}`, build);
      }
    });
  }

  if (mediasMore.length) {
    mediasMore.on('click', function () {
      mediasMore.addClass('button--loading').data('original-label', mediasMore.text()).text('loading...');

      setTimeout(getItems, 500);
    });
  }

  if (category === "press" || category === "publications") {
    build(window[category], false);
  } else {
    $.get(`${api}/${category}`, build);
  }
}

function build(medias, response) {
  const itemsPerView = isMobileSize() ? 3 : 6;

  // if (response) {
  //   medias.reverse();
  // }

  for (let i = 0; i < medias.length; i++) {
    grid.append(`
      <div class="thumb grid-item ${medias[i].playlist} ${(i < itemsPerView) ? 'show' : ''}">
        <a href="${medias[i].link}" target="_blank">
          <div class="thumb__picture thumb__picture--media">
            <span class="thumb__image" style="background-image:url('${medias[i].cover}');"></span>
          </div>
          <h5 class="thumb__caption">${medias[i].title}</h5>
        </a>
      </div>
    `);
  }

  if (medias.length) {
    mediasLoader.removeClass('medias__loader--loading');
  }

  if (medias.length > itemsPerView) {
    mediasMore.removeClass('button--loading').text(mediasMore.data('original-label')).show();
  }

  isotope = grid.isotope({
    itemSelector: '.grid-item',
    layoutMode: 'fitRows',
    filter: '.show'
  });
}

function getItems() {
  const itemsPerView = isMobileSize() ? 3 : 6;

  let gridItems;
  let gridItemsVisibleBefore;
  let gridItemsVisibleNow;

  if (isotopeFilter === '.show') {
    gridItems = grid.find('.grid-item').length;
    gridItemsVisibleBefore = grid.find('.grid-item:visible').length;
    gridItemsVisibleNow = (gridItemsVisibleBefore + itemsPerView) <= gridItems ? (gridItemsVisibleBefore + itemsPerView) : gridItems;

    grid.find('.grid-item:lt('+ gridItemsVisibleNow +')').addClass('show');

    isotope.isotope({ filter: '.show' });
  } else {
    gridItems = grid.find('.grid-item'+ isotopeFilter).length;
    gridItemsVisibleBefore = grid.find('.grid-item'+ isotopeFilter +':visible').length;
    gridItemsVisibleNow = (gridItemsVisibleBefore + itemsPerView) <= gridItems ? (gridItemsVisibleBefore + itemsPerView) : gridItems;

    grid.find('.grid-item'+ isotopeFilter +':lt('+ gridItemsVisibleNow +')').addClass('show');

    isotope.isotope({ filter: isotopeFilter + '.show' });
  }

  setTimeout(function () {
    if (gridItems === gridItemsVisibleNow) {
      mediasMore.hide();
    } else {
      mediasMore.removeClass('button--loading').text(mediasMore.data('original-label'));
    }
  }, 500);
}

if (window.page === 'media') {
  init();
}
