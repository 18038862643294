const overlay = $(".overlay");
const lightbook = $(".lightbook");
const lightbookOpen = $("#lightbook");
const lightbookClose = $(".lightbook__close");

function init() {
  lightbookOpen.on("click", open);
  lightbookClose.on("click", close);
  overlay.on("click", close);
}

function open() {
  overlay.addClass("overlay--visible");
  lightbook.addClass("lightbook--visible");
}

function close() {
  overlay.removeClass("overlay--visible");
  lightbook.removeClass("lightbook--visible");
}

init();
