import { isInArray, isMobileSize } from './helpers';

const api = '/wp-json/api/v1/youtube/playlist/thecharliekirkshow';

const homeShowCarousel = $('#homeShowCarousel');

let carousel;
let carouselPlayer = [];
let carouselCurrentVideo;
let carouselTotalVideos = 10;
let carouselVideos = [];
let carouselVideosMuted = true;

function init(playlist) {
  carousel = homeShowCarousel.find('.owl-carousel');

  for (let i = 0; i < carouselTotalVideos; i++) {
    carousel.append(`
      <div class="carousel__item" data-merge="3">
        <div class="drag-prev"></div>
        <div class="drag-next"></div>
        <div class="carousel__item-player carousel__content--lightblue">
          <div id="player${playlist[i].videoId}" class="carousel__content carousel__content--lightblue" data-id="${playlist[i].videoId}" style="background-image:url('${playlist[i].cover}');"></div>
        </div>
        <h5 class="carousel__caption carousel__caption--white">${playlist[i].title}</h5>
      </div>
    `);
  }

  homeShowCarousel.removeClass('carousel--loading');

  carousel.owlCarousel({
    items: 1,
    margin: 10,
    center: true,
    dots: false,
    merge: true,
    loop: true,
    video: true,
    onInitialized: function () {
      getNextVideo();

      carousel.find('.cloned').each(function (index, item) {
        const playerId = $(this).find('.carousel__item .carousel__content').attr('id');
        const playerNewId = playerId + Math.floor(Math.random() * 100 | 0) + 1;

        $(this).find('.carousel__item .carousel__content').attr('id', playerNewId);
      });
    }
  });

  carousel.on('changed.owl.carousel', function(e, a) {
    stopVideo();
  });

  $(document).on('click', '#homeShowCarousel .carousel__arrow-prev', function () {
    carousel.trigger('prev.owl.carousel');
  });

  $(document).on('click', '#homeShowCarousel .carousel__arrow-next', function () {
    carousel.trigger('next.owl.carousel');
  });
}

function getNextVideo () {
  var videoId = carousel.find('.owl-item.active').find('.carousel__content').data('id');
  var element = carousel.find('.owl-item.active').find('.carousel__content').attr('id')

  renderVideo(videoId, element);
}

function stopVideo() {
  carouselPlayer[carouselCurrentVideo].pauseVideo();
  setTimeout(getNextVideo, 500);
}

function renderVideo(videoId, element) {
  carouselCurrentVideo = element;

  if (!isInArray(element, carouselVideos)) {
    carouselVideos.push(element);

    carouselPlayer[element] = new YT.Player(element, {
      width: '100%',
      height: isMobileSize() ? '200' : '360',
      videoId: videoId,
      playerVars: {
        autoplay: 0,
        controls: 1,
        showinfo: 0,
        modestbranding: 1,
        loop: 1,
        fs: 0,
        cc_load_policy: 0,
        iv_load_policy: 3,
        autohide: 0,
        playsinline: 1,
      },
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange,
      },
    });
  } else {
    // carouselPlayer[carouselCurrentVideo].playVideo();
  }
}

function onPlayerReady(e) {
  // e.target.playVideo();

  if (carouselVideosMuted) {
    e.target.mute();
  }
}

function onPlayerStateChange (event) {
  carouselVideosMuted = carouselPlayer[carouselCurrentVideo].isMuted();

  if (event.data === 0) {
    carousel.trigger('next.owl.carousel');
  }
}

if (homeShowCarousel.length) {
  setTimeout(() => {
    $.get(api, init);
  }, 1000);
}