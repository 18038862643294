const fullsizer = $('.fullsizer');
const fullsizerClose = $('.fullsizer__close');

const allowedPages = ['podcast'];
const timeout = localStorage.getItem('fullsizer-timeout');

let canOpen = false;

function init() {
  fullsizerClose.on('click', close);

  for (let page in allowedPages) {
    if (allowedPages[page] === window.page) {
      canOpen = true;
    }
  }
  
  if (canOpen) {
    if (timeout) {
      const timeNow = new Date().getTime();
    
      if (timeNow >= timeout) {
        localStorage.removeItem('fullsizer-timeout');
      }
    } else {
      const minute = 60 * 1000;
      const hour = 60 * minute;
      const day = 24 * hour;
    
      setTimeout(function() {
        const time24Hours = new Date().getTime() + day;
    
        localStorage.setItem('fullsizer-timeout', time24Hours);
        
        open();
      }, 0);
    }
  }
}

function open() {
  fullsizer.addClass('fullsizer--visible');
}

function close() {
  fullsizer.removeClass('fullsizer--visible');
}

init();
