import { isMobileSize } from './helpers';

const grid = $('.grid');
const gridItem = $('.grid-item');
const gridFilters = $('.filters');
const gridFilter = $('.filter');
const gridSelect = $('.select');
const gridLoadMore = $('.button--load-more');

let isotope = null;
let isotopeFilter = '.show';

function resetItems() {
  const itemsPerView = isMobileSize() ? 3 : 6;

  let gridItems;
  let gridItemsVisibleBefore;
  let gridItemsVisibleNow;

  gridItem.removeClass('show');

  if (isotopeFilter === '.show') {
    gridItem.each(function (i) {
      const item = $(this);
  
      if (i < itemsPerView) {
        item.addClass('show');
      }
    });

    gridItems = grid.find('.grid-item').length;
    gridItemsVisibleBefore = grid.find('.grid-item:visible').length;
    gridItemsVisibleNow = (gridItemsVisibleBefore + itemsPerView) <= gridItems ? (gridItemsVisibleBefore + itemsPerView) : gridItems;

    if (gridItems === gridItemsVisibleNow) {
      gridLoadMore.hide();
    } else {
      gridLoadMore.removeClass('button--loading').text(gridLoadMore.data('original-label')).show();
    }

    isotope.isotope({ filter: isotopeFilter });
  } else {
    grid.find(isotopeFilter).each(function (i) {
      const item = $(this);
  
      if (i < itemsPerView) {
        item.addClass('show');
      }
    });

    gridItems = grid.find('.grid-item'+ isotopeFilter).length;
    gridItemsVisibleBefore = grid.find('.grid-item'+ isotopeFilter +':visible').length;
    gridItemsVisibleNow = (gridItemsVisibleBefore + itemsPerView) <= gridItems ? (gridItemsVisibleBefore + itemsPerView) : gridItems;

    if (gridItems === gridItemsVisibleNow) {
      gridLoadMore.hide();
    } else {
      gridLoadMore.removeClass('button--loading').text(gridLoadMore.data('original-label')).show();
    }

    isotope.isotope({ filter: isotopeFilter + '.show' });
  }
}

function getItems(itemsPerView) {
  let gridItems;
  let gridItemsVisibleBefore;
  let gridItemsVisibleNow;

  if (isotopeFilter === '.show') {
    gridItems = grid.find('.grid-item').length;
    gridItemsVisibleBefore = grid.find('.grid-item:visible').length;
    gridItemsVisibleNow = (gridItemsVisibleBefore + itemsPerView) <= gridItems ? (gridItemsVisibleBefore + itemsPerView) : gridItems;
    
    grid.find('.grid-item:lt('+ gridItemsVisibleNow +')').addClass('show');

    isotope.isotope({ filter: '.show' });
  } else {
    gridItems = grid.find('.grid-item'+ isotopeFilter).length;
    gridItemsVisibleBefore = grid.find('.grid-item'+ isotopeFilter +':visible').length;
    gridItemsVisibleNow = (gridItemsVisibleBefore + itemsPerView) <= gridItems ? (gridItemsVisibleBefore + itemsPerView) : gridItems;
    
    grid.find('.grid-item'+ isotopeFilter +':lt('+ gridItemsVisibleNow +')').addClass('show');
    
    isotope.isotope({ filter: isotopeFilter + '.show' });
  }

  setTimeout(function () {
    if (gridItems === gridItemsVisibleNow) {
      gridLoadMore.hide();
    } else {
      gridLoadMore.removeClass('button--loading').text(gridLoadMore.data('original-label'));
    }
  }, 500);
}

if (window.page === 'shop') {
  isotope = grid.isotope({
    itemSelector: '.grid-item',
    layoutMode: 'fitRows',
    filter: '.show',
  });
  
  if (gridFilters.length) {
    gridFilter.on('click', function () {
      const clickedFilter = $(this);
      const selectedFilter = clickedFilter.attr('data-filter');
  
      isotopeFilter = selectedFilter;
      
      gridFilter.removeClass('is-checked');
      clickedFilter.addClass('is-checked');
  
      if (gridSelect) {
        gridSelect.val(selectedFilter);
      }
  
      resetItems();
    });
  }
  
  if (gridSelect.length) {
    gridSelect.on('change', function () {
      const selectedFilter = $(this).val();
  
      isotopeFilter = selectedFilter;
  
      if (gridFilters) {
        gridFilter.removeClass('is-checked');
        gridFilters.find('[data-filter="'+ selectedFilter +'"]').addClass('is-checked');
      }
  
      resetItems();
    });
  }
  
  if (gridLoadMore.length) {
    gridLoadMore.on('click', function () {
      const itemsPerView = isMobileSize() ? 3 : 6;
    
      gridLoadMore.addClass('button--loading').data('original-label', gridLoadMore.text()).text('loading...');
    
      setTimeout(getItems, 500, itemsPerView);
    });
  }
}