import {
  capitalize,
  lowercase,
  isEmail,
  isFullname,
  isFunctionalKey,
  isText,
} from "./helpers";

const mailchimpURL =
  "//charliekirk.us20.list-manage.com/subscribe/post-json?u=870206b3673491b0962a188be&amp;id=7a6b4fbeb9&c=?";

const newsletter = $(".newsletter");
const newsletterName = newsletter.find('input[name="NAME"]');
const newsletterEmail = newsletter.find('input[name="EMAIL"]');
const newsletterSubmit = newsletter.find(".newsletter__button");

function newsletterSetInput(input) {
  input.parent().removeClass("newsletter__field--error");
  input.data("input-valid", true).removeClass("newsletter__input--error");
}

function newsletterSetInputError(input) {
  input.parent().addClass("newsletter__field--error");
  input.data("input-valid", false).addClass("newsletter__input--error");
}

function newsletterSetPlaceholder(input) {
  input.attr("placeholder", input.data("placeholder"));
}

function newsletterSetPlaceholderError(input) {
  input.attr("placeholder", input.data("placeholder-error")).focus();
}

newsletterName.on("keydown", function (e) {
  const code = e.keyCode;
  const char = String.fromCharCode(code);

  if (isFunctionalKey(code)) return;
  if (!isText(char)) return e.preventDefault();
});

newsletterName.on("change", function () {
  const input = $(this);

  newsletterSetInput(input);
  if (!isFullname(input.val())) newsletterSetInputError(input);
});

newsletterEmail.on("change", function () {
  const input = $(this);

  newsletterSetInput(input);
  if (!isEmail(input.val())) newsletterSetInputError(input);
});

newsletterSubmit.on("click", function (e) {
  const button = $(this);
  const form = button.closest(".newsletter__form");
  const formName = form.find('input[name="NAME"]');
  const formEmail = form.find('input[name="EMAIL"]');
  const formMessage = form.closest(".newsletter").find(".newsletter__message");

  e.preventDefault();

  if (!formName.val()) return newsletterSetPlaceholderError(formName);
  newsletterSetPlaceholder(formName);

  if (!formEmail.val()) return newsletterSetPlaceholderError(formEmail);
  newsletterSetPlaceholder(formEmail);

  if (!formName.data("input-valid")) return formName.focus();
  if (!formEmail.data("input-valid")) return formEmail.focus();

  formName.val(capitalize(formName.val()));
  formEmail.val(lowercase(formEmail.val()));

  button.addClass("newsletter__button--loading");

  $.ajax({
    type: "POST",
    cache: false,
    contentType: "application/json; charset=utf-8",
    crossDomain: true,
    data: form.serialize(),
    dataType: "jsonp",
    url: mailchimpURL,
    timeout: 5000,
    success: function (data) {
      window.gtag("event", "Newsletter", {
        event_category: "Newsletter",
        event_label: "Newsletter",
      });

      let message = data.msg;

      if (data.result === "success") {
        button.addClass("newsletter__button--success");

        formMessage.text(message).addClass("newsletter__message--show");

        setTimeout(function () {
          formMessage.text("").removeClass("newsletter__message--show");
        }, 1000);
      } else {
        if (message.indexOf("is already subscribed") !== -1) {
          message = formEmail.val() + " is already subscribed!";
        } else if (message === "0 - An email address must contain a single @") {
          message = "Please provide a valid email address";
        }

        button.addClass("newsletter__button--error");

        formMessage.text(message).addClass("newsletter__message--show");

        setTimeout(function () {
          formMessage.text("").removeClass("newsletter__message--show");
        }, 10000);
      }

      setTimeout(function () {
        formName.attr("disabled", false).val("");
        formEmail.attr("disabled", false).val("");

        button
          .removeClass(
            "newsletter__button--loading newsletter__button--success newsletter__button--error"
          )
          .blur();
      }, 1000);
    },
  });

  /**
   * **WARNING**
   * Do not relocate this code!
   * Disable inputs must be performed after form serialization
   */
  formName.attr("disabled", true);
  formEmail.attr("disabled", true);
});
