import { isMobileSize } from './helpers';

const showcase1 = $('#showcase1');
const showcase2 = $('#showcase2');
const showcase3 = $('#showcase3');

function initOwls() {
  init(showcase1.find('.owl-carousel'), isMobileSize() ? 0 : 10000);
  init(showcase2.find('.owl-carousel'), 0);
  init(showcase3.find('.owl-carousel'), 5000);
}

function init(showcase, start) {
  showcase.owlCarousel({
    items: 1,
    margin: 0,
    center: true,
    dots: false,
    merge: true,
    loop: true,
    autoplay: false,
    autoplayTimeout: isMobileSize() ? 3000 : 8000,
    autoplayHoverPause: false,
    smartSpeed: 2000
  });

  setTimeout(function() { showcase.trigger('play.owl.autoplay') }, start);
}

if (window.page === 'home') {
  initOwls();

  $( window ).resize(initOwls);
}

