import { isMobileSize } from './helpers';

const viewport = $(window);
const header = $(".header");
const navbar = $(".navbar");
const burger = $(".burger");
const menu = $(".menu");

let headerIsShowingMenu = false;
let headerCanChangeColor = false;
let headerChangeColorAllowedPages = [
  "home",
  "about",
  "events",
  "media",
  "shop",
  "podcast",
  "support",
];

$(window).bind("pageshow", function(event) {
  if (event.originalEvent.persisted) {
    window.location.reload();
  }
});

function headerOpenMenu() {
  headerIsShowingMenu = true;

  header.height('100vh');
  header.addClass("header--opened");
  burger.addClass("burger--closed");

  setTimeout(function () {
    header.addClass("header--scroll");
  }, 500);
}

function headerCloseMenu() {
  headerIsShowingMenu = false;

  header.height(navbar.outerHeight());
  header.removeClass(["header--opened", "header--scroll"]);
  burger.removeClass("burger--closed");

  setTimeout(function () {
    header.removeClass("header--scroll");
  }, 500);
}

function headerToggleMenu() {
  if (!headerIsShowingMenu) {
    headerOpenMenu();
  } else {
    headerCloseMenu();
  }
}

function headerChangeColor() {
  const scrollTop = viewport.scrollTop();
  const headerHeight = header.height();
  if (scrollTop > headerHeight) {
    header.addClass("header--sticky");
  } else {
    header.removeClass("header--sticky");
  }
}

function headerReset() {
  if (headerIsShowingMenu) {
    headerCloseMenu();
  }

  header.height(navbar.outerHeight());
}

header.height(navbar.outerHeight());
menu.removeClass("menu--initial");

if (!isMobileSize()) {
  viewport.on("resize", headerReset);
}

burger.on("click", headerToggleMenu);

for (let page in headerChangeColorAllowedPages) {
  if (headerChangeColorAllowedPages[page] === window.page) {
    headerCanChangeColor = true;
  }
}

if (headerCanChangeColor) {
  viewport.scroll(headerChangeColor);
  headerChangeColor();
}
