const overlay = $('.overlay');
const lightbox = $('.lightbox');
const lightboxClose = $('.lightbox__close');

const allowedPages = ['home', 'about', 'events', 'media', 'shop', 'support'];
const timeout = localStorage.getItem('lightbox-timeout');

let canOpen = false;

function init() {
	lightboxClose.on('click', close);
	overlay.on('click', close);

  for (let page in allowedPages) {
    if (allowedPages[page] === window.page) {
      canOpen = true;
    }
  }

  if (canOpen) {
    if (timeout) {
      const timeNow = new Date().getTime();

      if (timeNow >= timeout) {
        localStorage.removeItem('lightbox-timeout');
      }
    } else {
      const minute = 60 * 1000;
      const hour = 60 * minute;
      const day = 24 * hour;

      setTimeout(function() {
        const time24Hours = new Date().getTime() + day;

        localStorage.setItem('lightbox-timeout', time24Hours);

        open();
      }, minute / 2);
    }
  }
}

function open() {
  overlay.addClass('overlay--visible');
  lightbox.addClass('lightbox--visible');
}

function close() {
  overlay.removeClass('overlay--visible');
  lightbox.removeClass('lightbox--visible');
}

init();
